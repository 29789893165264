@tailwind base;
@tailwind components;
@tailwind utilities;

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;

}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;

}

.container {
  position: relative;
}

.container,
#interactive.viewport {
  width: 400px;
  height: 300px;
}

#interactive.viewport canvas,
video {
  width: 310px;
  height: 290px;
  position: absolute;
  top: 0;
  left: 0;
}

#interactive.viewport canvas.drawingBuffer,
video.drawingBuffer {
  width: 319px;
  height: 243px;
  margin-top: 2rem;
}

@keyframes pulsar {
  0%, 100% {
      transform: scale(1);
      opacity: 1;
  }
  40% {
      transform: scale(1.2);
      opacity: 0.7;
  }
  
  60% {
      transform: scale(1.2);
      opacity: 0.7;
  }

}

.pulsar {
  animation: pulse 2.5s infinite;
}